import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import styles from "../call_list.module.css";

export default function CallListFilters(props) {
  const { filters = {}, sites, setSearchParams, search_params } = props;

  //takes in either site object, site_id, or nothing
  const render_site_option = (site) => {
    if ([null, undefined].includes(site)) {
      return "";
    } else if (site.constructor === Object) {
      return `${site.code} - ${site.name}`;
    } else if (site.constructor === String) {
      console.log("the constuct", site);
      return render_site_option(sites.find((s) => s.id === parseInt(site)));
    } else return "";
  };

  return (
    <div className={styles.filter_wrapper}>
      <div className={styles.filter_item}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={sites.map(render_site_option)}
          value={render_site_option(search_params.get("site_id"))}
          size="small"
          sx={{
            "& .MuiInputBase-root": {
              fontSize: "12px",
              margin: 0,
              padding: 0,
            },
            "& .MuiAutocomplete-root": {
              margin: 0,
              padding: 0,
            },
            "& .MuiFormLabel-root": {
              fontSize: "1rem",
              lineHeight: "1.2rem",
            },
            width: 280,
          }}
          renderInput={(params) => <TextField {...params} label="Site" />}
          onChange={(e, value) => {
            let site_id = sites.find(
              (s) => s.code === value?.split(" ")[0],
            )?.id;

            if (site_id) {
              search_params.set("site_id", site_id);
              setSearchParams(search_params);
            } else {
              search_params.delete("site_id");
              setSearchParams(search_params);
            }
          }}
        />
      </div>
      <div className={styles.filter_item}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={["complete", "upcoming", "due", "past-due", "overdue"]}
          value={search_params.get("status") || ""}
          size="small"
          sx={{
            "& .MuiInputBase-root": {
              fontSize: "12px",
              margin: 0,
              padding: 0,
            },
            "& .MuiAutocomplete-root": {
              margin: 0,
              padding: 0,
            },
            "& .MuiFormLabel-root": {
              fontSize: "1rem",
              lineHeight: "1.2rem",
            },
            width: 180,
          }}
          renderInput={(params) => <TextField {...params} label="Status" />}
          onChange={(e, status) => {
            let _search_params = { ...search_params };
            if (status) {
              _search_params.status = status;
              setSearchParams(_search_params);
            } else {
              delete _search_params.status;
              setSearchParams(_search_params);
            }
          }}
        />
      </div>
    </div>
  );
}
