import React, { Component } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import EDC from "views/edc";
import Subjects from "views/subjects";
import Sites from "views/sites";
import EdcGrid from "views/edc_grid";
import Users from "views/users";
import RandomizeSubject from "views/randomize";
import RandomizeResult from "views/randomize/randomize_result";
import Audit from "views/audit";
import Consent from "views/consent";
import UserProfile from "views/user_profile";
import Admin from "views/admin";
import CrfBuilder from "views/crf_builder";
import VisitMapBuilder from "views/visit_map_builder";
import DrugManagement from "views/drug_management";
import Reports from "views/reports";
import Queries from "views/queries";
import AE from "views/adverse_events";
import SelectDomain from "views/select_domain";
import Eligibility from "views/eligibility";
import RoleConfig from "views/role_config";
import PasswordResetAuthenticated from "views/password_reset_authenticated";
import EnrollmentComplete from "views/enrollment_complete";
import CallList from "views/call_list";
import Data from "views/data";
import ConfirmAddSubject from "views/confirm_add_subject";

const ProtectedRoute = (props) => {
  const { permissions, required } = props;
  return permissions.includes(required) ? (
    <Outlet />
  ) : (
    <Navigate to={"/subjects"} />
  );
};

export default function Router() {
  const user_data = useSelector((state) => state.user.userData);
  return (
    <Routes>
      <Route
        path="study/:study_id/site/:site_id/subjects/:subject_id/edc/visit/:visit_index"
        element={<EDC />}
      />
      <Route
        path="study/:study_id/site/:site_id/subjects/:subject_id/edc/visit/:visit_index/:form_type/:crf_id"
        element={<EDC />}
      />
      <Route
        path="study/:study_id/site/:site_id/subjects/:subject_id/edc/visit/:visit_index/:form_type/:crf_id/:multi_entry_crf_id"
        element={<EDC />}
      />

      <Route
        path="study/:study_id/site/:site_id/subjects/:subject_id/view/*"
        element={<EdcGrid />}
      />
      <Route path="subjects" element={<Subjects />} />
      <Route
        path="randomize/result/:randomization_id"
        element={<RandomizeResult />}
      />
      <Route path="audit" element={<Audit />} />
      <Route path="select_domain" element={<SelectDomain />} />
      <Route path="confirm_add_subject" element={<ConfirmAddSubject />} />
      <Route path="consent_subject/:domain_id" element={<Consent />} />
      <Route
        path="consent_subject/:domain_id/:existing_subject_id"
        element={<Consent />}
      />
      <Route
        path="consent_subject/:domain_id/:existing_subject_id"
        element={<Consent />}
      />
      <Route
        path="randomize_subject/:consentID"
        element={<RandomizeSubject />}
      />
      <Route
        path="enrollment_complete/:subject_id"
        element={<EnrollmentComplete />}
      />
      <Route path="eligibility/:domain_id" element={<Eligibility />} />
      <Route path="user_profile" element={<UserProfile />} />
      <Route path="drug_management" element={<DrugManagement />} />
      <Route path="reports" element={<Reports />} />
      <Route path="query" element={<Queries />} />
      <Route path="ae" element={<AE />} />
      <Route path="call_list" element={<CallList />} />
      <Route path="password_reset" element={<PasswordResetAuthenticated />} />
      <Route path="admin/*" element={<Admin />} />
      <Route
        element={
          <ProtectedRoute
            permissions={user_data.permissions}
            required={"crf_builder"}
          />
        }
      >
        <Route path="crf_builder/:crf_id" element={<CrfBuilder />} />
      </Route>
      <Route
        element={
          <ProtectedRoute
            permissions={user_data.permissions}
            required={"crf_builder"}
          />
        }
      >
        <Route path="crf_builder/:crf_id/:version" element={<CrfBuilder />} />
      </Route>
      <Route
        element={
          <ProtectedRoute
            permissions={user_data.permissions}
            required={"visit_map_builder"}
          />
        }
      >
        <Route
          path="visit_map_builder/:study_id/:version"
          element={<VisitMapBuilder />}
        />
      </Route>
      <Route
        element={
          <ProtectedRoute
            permissions={user_data.permissions}
            required={"role_config"}
          />
        }
      >
        <Route path="role_config" element={<RoleConfig />} />
      </Route>
      <Route
        element={
          <ProtectedRoute
            permissions={user_data.permissions}
            required={"data_export"}
          />
        }
      >
        <Route path="data_export" element={<Data />} />
      </Route>
    </Routes>
  );
}
