import React, { useState, useRef, useEffect } from "react";
import * as requests from "util/requests";
import { TextField, Checkbox } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import styles from "./date.module.css";
import moment from "moment";
import DataCompleteChangeModal from "ui/crfs/data_complete_change_modal";
import AuditIconUI from "ui/crfs/audit_icon_ui";

let oldValue = null;

export default function Date(props) {
  const {
    field,
    title,
    options,
    sub_title,
    uval,
    onUpdate,
    crf_id,
    field_id,
    query,
    setQuery,
    subject_id,
    domain_id,
    calcUpdateToCrfStatus,
    enableQuery,
    show_checkmark,
    crfStatus,
    read_only,
    toggleHistory,
    selected_audit_icon,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  //we must set localVal anytime the UI changes to keep it in sync, the DatePicker doesn't seem to like being at a different state than localVal, perhaps it becomes uncontrolled
  const [localVal, setLocalVal] = useState(field.value_obj.value || null);
  const [showingCompleteChangeModal, setShowingCompleteChangeModal] =
    useState(false);

  //useEffect to set the oldValue incase we just use the text entry
  useEffect(() => {
    oldValue = field.value_obj.value;
  }, []);

  const commonUpdateValueActions = (value, reason = null) => {
    // setLocalVal(value);
    // console.log("commonUpdateValueActions", value);
    let obj = {
      value,
      value_name: field.value_name || null,
      reason,
    };
    onUpdate(obj);
  };

  const considerUpdate = (value = null) => {
    if (crfStatus === "data_complete") {
      setShowingCompleteChangeModal(true);
      return;
    }
    commonUpdateValueActions(value || localVal);
  };

  const acceptTempStorage = (reason) => {
    commonUpdateValueActions(localVal, reason);
    oldValue = localVal;
    setShowingCompleteChangeModal(false);
  };

  const closeModal = () => {
    setLocalVal(oldValue);
    oldValue = null;
    setShowingCompleteChangeModal(false);
  };

  const openDateUI = () => {
    oldValue = localVal;
    setIsOpen(true);
  };
  const closeDateUI = () => {
    setIsOpen(false);
    // considerUpdate();
  };

  const handleOnChange = (value) => {
    if (value && value.isValid()) setLocalVal(value.format("yyyy-MM-DD"));
    if (isOpen) {
      considerUpdate(value.format("yyyy-MM-DD"));
    }
  };

  const onBlur = (e) => {
    if (e.target.value) considerUpdate();
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      //todo - need to figure out how to blur the field using an enter
    }
  };
  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.input_flex}>
        <div className={styles.left_split}>
          <NotificationWidget {...props} currentValueDisplay={localVal} />
        </div>
        {read_only || field.read_only ? (
          <div className={styles.right_split}>
            <div>{localVal}</div>
            <div className={styles.audit_icon}>
              <AuditIconUI
                onClick={() => toggleHistory({ field_id: field_id })}
                selected={selected_audit_icon}
              />
            </div>
          </div>
        ) : (
          <div className={styles.right_split}>
            <div className={styles.date_wrapper}>
              <div>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    open={isOpen}
                    format="YYYY / MM / DD"
                    value={(localVal && moment(localVal, "yyyy-MM-DD")) || null}
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "5px 10px",
                        width: "120px",
                      },
                      "& .MuiInputAdornment-root": { marginLeft: 0 },
                      "& .MuiButtonBase-root": { paddingLeft: 0 },
                    }}
                    onChange={handleOnChange}
                    onOpen={openDateUI}
                    onClose={closeDateUI}
                    slotProps={{ textField: { onBlur, onKeyDown } }}
                  />
                </LocalizationProvider>
              </div>
              <div className={styles.input_description}>(YYYY / MM / DD)</div>
            </div>
            <div className={styles.audit_icon}>
              <AuditIconUI
                onClick={() => toggleHistory({ field_id: field_id })}
                selected={selected_audit_icon}
              />
            </div>
          </div>
        )}
      </div>

      <DividerLine />
      <DataCompleteChangeModal
        isOpen={showingCompleteChangeModal}
        close={closeModal}
        onYes={acceptTempStorage}
      />
    </div>
  );
}
