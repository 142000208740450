import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import styles from "./signature_password.module.css";

export default function SignaturePassword(props) {
  const {
    submit_text,
    onSubmit,
    onEnterKey = () => {},
    disabled,
    password = "",
    setPassword = () => {},
    showCancel,
    cancel,
    notice,
  } = props;

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onKeyDown = (key) => {
    setMessage("");
    if (key.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (password.length < 8) {
      return;
    }
    setLoading(true);

    onSubmit(password).then((success) => {
      if (!success) {
        setMessage("Your password was incorrect.");
        setPassword("");
      }
      setLoading(false);
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.password_wrapper}>
        <TextField
          id="esign-signature"
          label="Password"
          variant="standard"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={onKeyDown}
          value={password}
          fullWidth
          autoFocus
        />
      </div>
      <div>{notice}</div>
      <div className={styles.buttons}>
        {showCancel ? (
          <LoadingButton variant="outlined" loading={false} onClick={cancel}>
            Cancel
          </LoadingButton>
        ) : null}

        <LoadingButton
          disabled={password.length < 8}
          variant="contained"
          loading={loading}
          onClick={handleSubmit}
        >
          {submit_text || "SIGN"}
        </LoadingButton>
      </div>
      {message}
    </div>
  );
}
