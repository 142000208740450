import React, { useRef, useEffect, useState } from "react";
import { get } from "util/requests";
import { DataGrid } from "@mui/x-data-grid";
import styles from "./call_list.module.css";
import moment from "moment";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CallListTable from "./components/call_list_table";
import Filters from "./components/filters";
import { useSearchParams } from "react-router-dom";

export default function SubjectCallList() {
  const [subjects, setSubjects] = useState([]);
  const [sites, setSites] = useState([]);

  const [search_params, setSearchParams] = useSearchParams();
  console.log(search_params);
  const study_id = useSelector((state) => state.study.active_study_id);

  useEffect(() => {
    get(`/subject_call_list/${study_id}`, { params: search_params }).then(
      (ret) => {
        setSubjects(ret.data.subjects);
        setSites(ret.data.sites);
      },
    );
  }, [search_params]);

  return (
    <div className={styles.flex}>
      <div className={styles.header}>
        <div>
          <h3>Subject Call List</h3>
        </div>
        <div className={styles.search_wrapper}></div>
      </div>
      <Filters
        sites={sites}
        setSearchParams={setSearchParams}
        search_params={search_params}
      />
      <CallListTable subjects={subjects} study_id={study_id} />
    </div>
  );
}
