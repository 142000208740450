import { useState, useRef } from "react";
import { decode } from "html-entities";
import { TextField } from "@mui/material";
import styles from "../crf_builder.module.css";

export default function EditableText(props) {
  const { text = null, onChange, width = 400, placeholder = "" } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [localText, setLocalText] = useState(text || placeholder);
  const ref = useRef(null);

  const parseTextFromHtml = (html) => {
    console.log(html);

    let text = new DOMParser().parseFromString(html, "text/html")
      .documentElement.textContent;
    text = decode(text, { level: "html5" });
    console.log(text);
    text = text.replaceAll("\n", "").replaceAll("\t", "");
    return text;
  };
  if (isEdit)
    return (
      <div className={styles.editable_text}>
        <TextField
          placeHolder={placeholder}
          multiline
          autoFocus
          ref={ref}
          value={localText}
          onChange={(e) => {
            setLocalText(parseTextFromHtml(e.target.value));
            // onChange(parseTextFromHtml(e.target.value));
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setLocalText(parseTextFromHtml(e.target.value));
              setIsEdit(false);
              onChange(parseTextFromHtml(e.target.value));
            }
          }}
          onBlur={(e) => {
            setLocalText(parseTextFromHtml(e.target.value));
            setIsEdit(false);
            onChange(parseTextFromHtml(e.target.value));
          }}
          autoComplete="off"
          sx={{
            "& .MuiInputBase-input": {
              padding: "5px",
              textAlign: "center",
              width: `${width}px`,
              fontSize: 14,
            },
          }}
          onFocus={(e) => {
            e.target.select();
          }}
        />
      </div>
    );
  else {
    return (
      <div className={styles.editable_text}>
        <div
          className={styles.internal_editable_text_field}
          onChange={(e) =>
            setLocalText(parseTextFromHtml(e.currentTarget.innerHTML))
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setLocalText(parseTextFromHtml(e.currentTarget.innerHTML));
              setIsEdit(false);
              onChange(e.currentTarget.innerHTML);
            }
          }}
          onBlur={(e) => {
            setLocalText(parseTextFromHtml(e.currentTarget.innerHTML));
            setIsEdit(false);
          }}
          onClick={() => {
            setIsEdit(true);
          }}
        >
          {localText}
        </div>
      </div>
    );
  }
}
