import React, { useEffect, useRef } from "react";
import styles from "./header.module.css";
import { useSelector } from "react-redux";
import act_global_logo from "assets/act_global_logo.png";
import escape_core_logo from "assets/escape_core_logo.png";
import basking_logo from "assets/basking_logo.jpg";
import AppsIcon from "@mui/icons-material/Apps";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import BreadCrumbs from "./components/breadcrumbs";
import router from "routes";

export default function Header(props) {
  const ref = useRef(null);
  const user_data = useSelector((state) => state.user.userData);
  const study_id = useSelector((state) => state.study.active_study_id);

  useEffect(() => {
    props.setWaffleRef(ref);
  }, []);

  return (
    <div className={styles.header_wrapper}>
      <div className={styles.hflex}>
        <div ref={ref} className={styles.apps_icon_wrapper}>
          <AppsIcon
            sx={{
              color: "black",
              fontSize: "34px",
              lineHeight: "34px",
              verticalAlign: "middle",
              cursor: "pointer",
            }}
            onClick={props.toggleModuleBar}
          />
        </div>
        {study_id === 1 ? (
          <img src={act_global_logo} height="40px" className={styles.logo} />
        ) : null}
        {study_id === 2 ? (
          <img src={escape_core_logo} height="30px" className={styles.logo} />
        ) : null}
        {study_id === 3 ? (
          <img src={basking_logo} height="36px" className={styles.logo} />
        ) : null}
        {![1, 2, 3].includes(study_id) ? (
          <div className={styles.text_logo}>
            {user_data.studies.find((s) => s.id === study_id)?.name}
          </div>
        ) : null}
        <BreadCrumbs />
      </div>
      {window.location.hostname.includes("test.platform") ? (
        <div className={styles.staging_text}>STAGING</div>
      ) : null}
      {window.location.hostname.includes("localhost") ? (
        <div className={styles.staging_text}>DEV</div>
      ) : null}
      {window.location.hostname.includes("training.platform") ? (
        <div className={styles.staging_text}>TRAINING</div>
      ) : null}
      {window.location.hostname.includes("qa.platform") ? (
        <div className={styles.staging_text}>QUALITY ASSURANCE</div>
      ) : null}
      <div className={styles.settings_icons_wrapper}>
        <div
          className={styles.name_role_set}
          onClick={() => {
            router.navigate("/user_profile");
          }}
        >
          <div className={styles.username_text}>{`${user_data.first_name} ${
            user_data.last_name && user_data.last_name[0]
          }.`}</div>

          {user_data.roles?.length > 1 ? (
            <div>
              {
                user_data.roles.find((r) => r.id === user_data.active_role_id)
                  ?.name
              }
            </div>
          ) : null}
        </div>
        <div className={styles.settings_icon}>
          <SettingsOutlinedIcon
            sx={{
              color: "#555",
              fontSize: "26px",
              lineHeight: "26px",
              cursor: "pointer",
              marginRight: 0,
            }}
            onClick={() => {
              router.navigate("/user_profile");
            }}
          />
        </div>
      </div>
    </div>
  );
}
