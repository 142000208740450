import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { get } from "util/requests";
import moment from "moment";
import styles from "./data.module.css";

export default function Data(props) {
  const [spinning, setSpinning] = useState(false);
  const download_data = () => {
    setSpinning(true);
    get("export_data").then((ret) => {
      let { data, environment } = ret.data;
      const fileName = `actg_${environment}`;
      const anchor = document.createElement("a");
      const type = "data:application/octet-stream";
      const url = "test.csv";
      const filename = `${fileName} - ${moment().format("YYYY-MM-DD HHmmss")}.csv`;
      const newWindow = true;

      anchor.setAttribute("href", `${type},${encodeURIComponent(data)}`);
      anchor.setAttribute("download", filename);
      anchor.style.display = "none";
      anchor.textContent = url;

      if (newWindow) {
        anchor.setAttribute("target", "_blank");
      }

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      setSpinning(false);
    });
  };
  return (
    <div>
      <div>
        <h3>Data</h3>
      </div>
      <LoadingButton
        variant="contained"
        loading={spinning}
        onClick={download_data}
        sx={{
          fontSize: 14,
          padding: "2px",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        Download Subject CSV
      </LoadingButton>
    </div>
  );
}
