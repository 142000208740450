import { useEffect, useState } from "react";
import styles from "./crf.module.css";
import { get, post } from "util/requests";
import RadioGroup from "ui/crfs/radio_group";
import Segment from "ui/crfs/segment";
import Year from "ui/crfs/year";
import Select from "ui/crfs/select";
import Time from "ui/crfs/time";
import Date from "ui/crfs/date";
import DropDown from "ui/crfs/drop_down";
import Text from "ui/crfs/text";
import Int from "ui/crfs/int";
import Decimal from "ui/crfs/decimal";
import BloodPressure from "ui/crfs/blood_pressure";
import DateTime from "ui/crfs/date_time";
import Heading from "ui/crfs/heading";
import Weight from "ui/crfs/weight";
import { cloneDeep } from "lodash";
import CrfForm from "./crf_form";
import LogFormTableWrapper from "./log_form_table";
import ProtovolDeviationsView from "./protocol_deviations";
import AdverseEventsView from "./adverse_events";

export default function Crf(props) {
  const { form_type, multi_entry_crf_id = null } = props;

  if (multi_entry_crf_id && form_type === "multi_entry") {
    return <CrfForm {...props} />;
  } else if (form_type === "multi_entry") {
    return <LogFormTableWrapper {...props} />;
  } else {
    return <CrfForm {...props} />;
  }
}
