import { DataGrid } from "@mui/x-data-grid";
import router from "routes";
import { get } from "util/requests";
import colors from "colors";
import moment from "moment";

import { CheckIcon } from "ui/icons";

const columns = [
  {
    field: "id",
    headerName: "Subject ID",
    width: 90,
  },
  {
    field: "site_id",
    headerName: "Site ID",
    width: 90,
  },
  {
    field: "site_name",
    headerName: "Site",
    width: 160,
  },
  {
    field: "first_randomized_on",
    headerName: "Enrolled On",
    width: 120,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => {
      return moment(row.modified_on).format("yyyy-MMM-DD");
    },
  },
  {
    field: "window_30d",
    headerName: "30D Window",
    width: 320,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row: { window_30d } }) => (
      <div>
        {window_30d.start} {"<"} <strong>{window_30d.target}</strong> {"<"}{" "}
        {window_30d.end}
      </div>
    ),
  },
  {
    field: "status_30d",
    headerName: "30D Follow-Up",
    width: 200,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => {
      if (row.status_30d === "complete") {
        return <CheckIcon fontSize="18px" />;
      } else {
        return row.status_30d;
      }
    },
  },
  {
    field: "window_90d",
    headerName: "90D Window",
    width: 320,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row: { window_90d } }) => (
      <div>
        {window_90d.start} {"<"} <strong>{window_90d.target}</strong> {"<"}{" "}
        {window_90d.end}
      </div>
    ),
  },
  {
    field: "status_90d",
    headerName: "90D Follow-Up",
    width: 200,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => {
      if (row.status_90d === "complete") {
        return <CheckIcon fontSize="18px" />;
      } else {
        return row.status_90d;
      }
    },
  },
];

export default function CallListTable(props) {
  const { subjects, study_id } = props;

  return (
    <DataGrid
      rows={subjects}
      columns={columns}
      disableColumnSelector
      disableRowSelectionOnClick
      disableColumnMenu
      pageSize={10}
      rowsPerPageOptions={[10]}
      hideFooterSelectedRowCount
      pageSizeOptions={[10]}
      columnHeaderHeight={34}
      rowHeight={34}
      autoPageSize
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      sx={{
        boxShadow: 0,
        border: 1,
        borderColor: "#f0f0f0",
        "& .MuiDataGrid-cell:hover": {
          color: "#8349fd",
          cursor: "pointer",
        },
        "& .MuiDataGrid-columnHeaders": {
          // backgroundColor: "#f7f3fe",
          backgroundColor: colors.table_header_background,
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
          color: "#333",
        },
      }}
      onRowClick={({ row }) => {
        router.navigate(
          `/study/${study_id}/site/${row.site_id}/subjects/${encodeURIComponent(row.id)}/view`,
        );
      }}
    />
  );
}
