import { useEffect, useState } from "react";
import styles from "./data_complete_change_modal.module.css";
import { get, post } from "util/requests";

import { cloneDeep } from "lodash";
import { useLocation } from "react-router-dom";
import router from "routes";
import { Dialog, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export default function DataCompleteChangeModal(props) {
  const { isOpen, close, onYes } = props;
  const [modalText, setModalText] = useState("");

  return (
    <Dialog open={isOpen} onClose={close}>
      <div className={styles.modal_wrapper}>
        <div className={styles.modal_title}>Update Completed Data</div>
        <div className={styles.modal_body}>
          <div>The data entry for this CRF has been set to complete.</div>
          <div className={styles.are_you_sure}>
            If you are sure you want to make this update, please enter a reason
            why this data is being changed.
          </div>
          <div className={styles.text_entry}>
            <TextField
              placeholder=""
              value={modalText}
              onChange={(e) => setModalText(e.target.value)}
              autoComplete="off"
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  paddingLeft: "10px",
                  textAlign: "left",
                },
              }}
              fullWidth
              multiline
            />
          </div>
        </div>
        <div className={styles.modal_button_wrapper}>
          <LoadingButton
            disabled={modalText.length < 8}
            variant="contained"
            loading={false}
            onClick={() => onYes(modalText)}
          >
            Continue
          </LoadingButton>
          <LoadingButton
            disabled={false}
            variant="outlined"
            loading={false}
            onClick={close}
          >
            Cancel
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );
}
