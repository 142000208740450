import { Fragment } from "react";
import { cloneDeep } from "lodash";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import EditableText from "./editable_text";
import styles from "../crf_builder.module.css";
import AddIcon from "@mui/icons-material/Add";

export default function Logic(props) {
  const {
    field,
    setMainCrfOptions,
    updateField,
    setReadOnly,
    setRequired,
    setDomainModalIsOpenData,
    setRegionModalisOpenData,
    regions,
  } = props;

  const toggleDisplayLogic = (field_id) => {
    let _field = field;
    if (!_field.display_logic || !_field.display_logic.rules?.length) {
      _field.display_logic = {
        type: "and",
        rules: [{ field_id: "", logic_type: "", value: "" }],
      };
    } else {
      delete _field.display_logic;
    }
    updateField(field_id, _field);
  };

  const setDisplayIfLogic = (field_id, rule_index, key, value) => {
    field.display_logic.rules[rule_index][key] = value;
    updateField(field_id, field);
  };

  const addLogicRow = (field_id) => {
    field.display_logic.rules.push({ field_id: "", logic_type: "", value: "" });
    updateField(field_id, field);
  };

  if (field.display_logic?.field_id && field.display_logic?.value) {
    field.display_logic = field.display_logic = {
      type: "and",
      rules: [field.display_logic],
    };
  }

  return (
    <div>
      <div className={styles.flex}>
        <div
          className={styles.add_button}
          onClick={() =>
            setDomainModalIsOpenData({
              id: field.id,
              activeDomains: field.domains || [],
            })
          }
        >
          domains
        </div>
        {(field.domains &&
          field.domains.constructor === Array &&
          field.domains.join(",")) ||
          "all"}
      </div>
      <div className={styles.flex}>
        <div
          className={styles.add_button}
          onClick={() =>
            setRegionModalisOpenData({
              id: field.id,
              activeRegions: field.regions || [],
            })
          }
        >
          regions
        </div>
        {(field.regions &&
          field.regions.constructor === Array &&
          field.regions
            .map((id) => {
              return regions.find((r) => r.id === id)?.code || null;
            })
            .filter((i) => i)
            .join(",")) ||
          "all"}
      </div>
      <div
        className={styles.add_button}
        onClick={() => toggleDisplayLogic(field.id)}
      >
        display if
      </div>
      <div>
        {field.display_logic?.rules?.map((logic, rule_index) => (
          <div className={styles.flex_row}>
            <EditableText
              text={logic.field_id || ""}
              placeholder="field_id"
              onChange={(value) => {
                setDisplayIfLogic(field.id, rule_index, "field_id", value);
              }}
            />
            <Select
              value={logic.logic_type || ""}
              sx={{
                fontSize: 14,
                minWidth: 200,
                height: 30,
                padding: 0,
                alignSelf: "center",
              }}
              onChange={(e) =>
                setDisplayIfLogic(
                  field.id,
                  rule_index,
                  "logic_type",
                  e.target.value,
                )
              }
            >
              {[
                { label: "equals", value: "equals" },
                { label: "not_equals", value: "not_equals" },
              ].map((o) => (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
            <EditableText
              text={logic.value || ""}
              placeholder={"field_value"}
              onChange={(value) => {
                setDisplayIfLogic(field.id, rule_index, "value", value);
              }}
            />{" "}
          </div>
        ))}
        {field.display_logic?.rules?.length > 0 ? (
          <div>
            <AddIcon onClick={() => addLogicRow(field.id)} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
