import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./crf.module.css";
import { get, post, _delete } from "util/requests";
import Segment from "ui/crfs/segment";
import { cloneDeep } from "lodash";
import { useLocation } from "react-router-dom";
import router from "routes";
import { Dialog } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment";

const arrayHaveCommonItems = (arr1, arr2) => {
  if (
    arr1 &&
    arr1.constructor === Array &&
    arr2 &&
    arr2.constructor === Array
  ) {
    return arr1.some((item) => arr2.includes(item));
  } else return false;
};

export default function CrfForm(props) {
  const {
    crf_id,
    subject_id,
    domain_id,
    visit_index,
    updateCrfQueryStatus,
    updateCrfStatus,
    form_type,
    multi_entry_crf_id = null,
    site_id,
    fetchSidebarData,
  } = props;
  const location = useLocation();
  const [crf_data, setCrfData] = useState(null);
  const [sae_adjudication_data, setSaeAdjudicationData] = useState(null);
  const [sae_medra_data, setSaeMedraData] = useState(null);
  const [crfStatus, setCrfStatus] = useState(null);
  const [crfSignStatus, setCrfSignStatus] = useState(null);
  const [
    showingDataCompleteMakeChangeModal,
    setShowingDataCompleteMakeChangeModal,
  ] = useState(false);

  const permissions = useSelector((state) => state.user.userData.permissions);
  const user_domains = useSelector((state) => state.user.userData.domains);
  const study_id = useSelector((state) => state.study.active_study_id);

  let linked_sae_adjudication_crf_id = null;
  let linked_sae_medra_crf_id = null;

  useEffect(() => {
    if (form_type === "multi_entry") {
      if (crf_id !== "adverse_events") {
        get(
          `/subject_mutli_entry_crf_data/${subject_id}/${props.crf_id}/${multi_entry_crf_id}`,
        ).then((ret) => {
          setCrfData({ ...ret.data, multi_entry_crf_id });
          setCrfStatus(ret.data.status || "");
          setCrfSignStatus(ret.data.qc_status || []);
          post("/footprint", {
            path: location.pathname,
            action: "pageload",
            name: `crf-${ret.data.name}`,
            subject_id,
            study_id,
            site_id,
          });
        });
      } else {
        get(
          `/subject_mutli_entry_crf_data/${subject_id}/${props.crf_id}/${multi_entry_crf_id}`,
        ).then((ret) => {
          setCrfData({ ...ret.data, multi_entry_crf_id });
          setCrfStatus(ret.data.status || "");
          // setSaeAdjudicationData(sae_adjudication_ret.data);
          // setSaeMedraData(sae_medra_ret.data);
          setCrfSignStatus(ret.data.qc_status || []);
          post("/footprint", {
            path: location.pathname,
            action: "pageload",
            name: `crf-${ret.data.name}`,
            subject_id,
            study_id,
            site_id,
          });

          linked_sae_adjudication_crf_id =
            ret.data.linked_sae_adjudication_crf_id || crypto.randomUUID();
          linked_sae_medra_crf_id =
            ret.data.linked_sae_medra_crf_id || crypto.randomUUID();
          get(
            `/subject_mutli_entry_crf_data/${subject_id}/sae_adjudication/${linked_sae_adjudication_crf_id}`,
          ).then((sae_adjudication_ret) =>
            setSaeAdjudicationData({
              ...sae_adjudication_ret.data,
              multi_entry_crf_id: linked_sae_adjudication_crf_id,
            }),
          );

          get(
            `/subject_mutli_entry_crf_data/${subject_id}/sae_medra/${linked_sae_medra_crf_id}`,
          ).then((sae_medra_ret) =>
            setSaeMedraData({
              ...sae_medra_ret.data,
              multi_entry_crf_id: linked_sae_medra_crf_id,
            }),
          );
        });
      }
    } else {
      get(`/subject_crf_data/${subject_id}/${props.crf_id}`)
        .then((ret) => {
          setCrfData(ret.data);
          setCrfStatus(ret.data.status || "");
          setCrfSignStatus(ret.data.qc_status || []);
          post("/footprint", {
            path: location.pathname,
            action: "pageload",
            name: `crf:${ret.data.name}`,
            subject_id,
            study_id,
            site_id,
          });
        })
        .catch(() => {
          router.navigate("/subjects");
        });
    }
  }, [crf_id, subject_id, multi_entry_crf_id]);

  const deleteForm = () => {
    return new Promise((resolve) => {
      _delete(
        `/mutli_entry_crf/${subject_id}/${props.crf_id}/${multi_entry_crf_id}`,
      ).then(() => {
        resolve();
        fetchSidebarData();
      });
    });
  };

  const setQuery = (crf_id, field_id, query) => {
    let data = cloneDeep(crf_data);
    let fields = data.design.fields;
    let field = fields.find((f) => f.id === field_id);
    field.query = query;
    setCrfData(data);
  };

  const updateFieldValue =
    (
      field_id,
      field_type,
      crf_id,
      multi_entry_crf_id = null,
      linked_crf_id = null,
    ) =>
    (value_obj) => {
      post("/crf_data", {
        crf_id,
        field_id,
        value_obj,
        patient_id: subject_id,
        study_id: study_id,
        field_type,
        form_type,
        multi_entry_crf_id,
        pathname: location.pathname,
        domain_id,
        timezone: moment.tz.guess(),
        crf_slug: crf_data.slug,
        visit_index,
        linked_crf_id,
      }).then(({ data }) => {
        // let _crf_data = cloneDeep(crf_data);
        //intentionally don't cloneDeep here
        let _crf_data = null;
        if (!multi_entry_crf_id) {
          _crf_data = { ...crf_data };
          setCrfSignStatus([]);
        } else if (multi_entry_crf_id === crf_data.multi_entry_crf_id) {
          _crf_data = { ...crf_data };
          setCrfSignStatus([]);
        } else if (
          multi_entry_crf_id === sae_adjudication_data.multi_entry_crf_id
        ) {
          _crf_data = { ...sae_adjudication_data };
        } else if (multi_entry_crf_id === sae_medra_data.multi_entry_crf_id) {
          _crf_data = { ...sae_medra_data };
        }

        let field = _crf_data.design.fields.find(
          (field) => field.id === field_id,
        );
        field.value_obj = value_obj;
        if (data.deviation_object && data.deviation_object.deviation === true) {
          field.value_obj.deviation = true;
        } else if (
          data.deviation_object &&
          data.deviation_object.violation === true
        ) {
          field.value_obj.violation = true;
        } else {
          delete field.value_obj.deviation;
          delete field.value_obj.violation;
        }

        if (
          !multi_entry_crf_id ||
          multi_entry_crf_id === crf_data.multi_entry_crf_id
        ) {
          setCrfData(_crf_data);
        } else if (multi_entry_crf_id === crf_data.multi_entry_crf_id) {
          setSaeAdjudicationData(_crf_data);
        }
      });

      // let _crf_id = form_type === "multi_entry" ? multi_entry_crf_id : crf_id;
      let _crf_id = crf_id;
      updateCrfStatus(_crf_id, "data", form_type, multi_entry_crf_id).then(
        (status) => {
          if (status) {
            // setCrfStatus(status);
            setTimeout(() => {
              let _crf_data = { ...crf_data };
              _crf_data.status = status;
              setCrfData(_crf_data);
            }, 250);
          }
          setCrfSignStatus([]);
        },
      );
    };

  return (
    <div className={styles.wrapper}>
      <div className={styles.subject_title_wrapper}>
        <div className={styles.subject_title}>
          <strong> Subject: {props.subject_id}</strong>
        </div>
      </div>
      <div className={styles.segmentWrapper}>
        <Segment
          title={
            (crf_data && crf_data.name && crf_data.name.toUpperCase()) || ""
          }
          crf_id={crf_id}
          multi_entry_crf_id={multi_entry_crf_id}
          crf_data={crf_data}
          crf_status={crf_data?.status}
          crf_sign_status={crfSignStatus}
          subject_id={subject_id}
          updateCrfStatus={(crf_id, status, multi_entry_crf_id) => {
            //this looks like some straight fuckery, but we maintain the status up a level so we can combine the actions on the crf with the actions on the crf list, like the setting of data state
            updateCrfStatus(crf_id, status, form_type, multi_entry_crf_id).then(
              (status) => {
                let _crf_data = { ...crf_data };
                _crf_data.status = status;
                setCrfData(_crf_data);
              },
            );
          }}
          updateCrfSignStatus={(crf_id, status, remove = false) => {
            let _status = [...crfSignStatus];
            let _crf_data = { ...crf_data };
            if (remove) {
              _status = _status.filter((s) => s !== status);

              _crf_data.qc_status = _crf_data.qc_status.filter(
                (s) => s !== status,
              );
            } else {
              _status.push(status);
              _crf_data.qc_status.push(status);
            }
            setCrfSignStatus(_status);
            setCrfData(_crf_data);
          }}
          showDeleteFormOption={
            form_type === "multi_entry" && crf_data?.write_permission
          }
          deleteForm={deleteForm}
          setQuery={setQuery}
          updateCrfQueryStatus={updateCrfQueryStatus}
          form_type={form_type}
          updateFieldValue={updateFieldValue}
          visit_index={visit_index}
          ignore_ref_height={true}
        ></Segment>
        {false && crf_id === "adverse_events" ? (
          <Segment
            title={
              (sae_adjudication_data &&
                sae_adjudication_data.name &&
                sae_adjudication_data.name.toUpperCase()) ||
              ""
            }
            crf_id={"sae_adjudication"}
            multi_entry_crf_id={sae_adjudication_data?.multi_entry_crf_id}
            linked_crf_id={multi_entry_crf_id}
            crf_data={sae_adjudication_data}
            crf_status={sae_adjudication_data?.status}
            crf_sign_status={crfSignStatus}
            subject_id={subject_id}
            updateCrfStatus={(crf_id, status, multi_entry_crf_id) => {
              //this looks like some straight fuckery, but we maintain the status up a level so we can combine the actions on the crf with the actions on the crf list, like the setting of data state
              updateCrfStatus(
                crf_id,
                status,
                form_type,
                multi_entry_crf_id,
              ).then((status) => {
                // setCrfStatus(status);
                let _sae_adjudication_data = { ...sae_adjudication_data };
                _sae_adjudication_data.status = status;
                setSaeAdjudicationData(_sae_adjudication_data);
              });
            }}
            updateCrfSignStatus={(crf_id, status) => {
              let _status = [...crfSignStatus];
              _status.push(status);
              setCrfSignStatus(_status);
            }}
            setQuery={setQuery}
            updateCrfQueryStatus={updateCrfQueryStatus}
            form_type={form_type}
            updateFieldValue={updateFieldValue}
            visit_index={visit_index}
            is_linked_crf={true}
          ></Segment>
        ) : null}
        {false && crf_id === "adverse_events" ? (
          <Segment
            title={
              (sae_medra_data &&
                sae_medra_data.name &&
                sae_medra_data.name.toUpperCase()) ||
              ""
            }
            crf_id={"sae_medra"}
            multi_entry_crf_id={sae_medra_data?.multi_entry_crf_id}
            linked_crf_id={multi_entry_crf_id}
            crf_data={sae_medra_data}
            crf_status={sae_medra_data?.status}
            crf_sign_status={crfSignStatus}
            subject_id={subject_id}
            updateCrfStatus={(crf_id, status, multi_entry_crf_id) => {
              //this looks like some straight fuckery, but we maintain the status up a level so we can combine the actions on the crf with the actions on the crf list, like the setting of data state
              updateCrfStatus(
                crf_id,
                status,
                form_type,
                multi_entry_crf_id,
              ).then((status) => {
                setCrfStatus(status);
              });
            }}
            updateCrfSignStatus={(crf_id, status, multi_entry_crf_id) => {
              let _status = [...crfSignStatus];
              _status.push(status);
              setCrfSignStatus(_status);
            }}
            setQuery={setQuery}
            updateCrfQueryStatus={updateCrfQueryStatus}
            form_type={form_type}
            updateFieldValue={updateFieldValue}
            visit_index={visit_index}
            is_linked_crf={true}
          ></Segment>
        ) : null}

        <div className={styles.protocol_vesion}>
          CRF Version {crf_data?.version}
        </div>
      </div>
    </div>
  );
}
