import React, { forwardRef } from "react";
import styles from "../download_menu.module.css";
import { get } from "util/requests";
import moment from "moment-timezone";

const DownloadMenu = (props, ref) => {
  const { getFunction, fileName } = props;
  const download = () => {
    getFunction().then((ret) => {
      const anchor = document.createElement("a");
      const type = "data:application/octet-stream";
      const url = "test.csv";
      const filename = `${fileName} - ${moment().format("YYYY-MM-DD HHmmss")}.csv`;
      const newWindow = true;

      anchor.setAttribute("href", `${type},${encodeURIComponent(ret.data)}`);
      anchor.setAttribute("download", filename);
      anchor.style.display = "none";
      anchor.textContent = url;

      if (newWindow) {
        anchor.setAttribute("target", "_blank");
      }

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    });
  };
  return (
    <div className={styles.hover_menu} ref={ref}>
      <div className={styles.menu_button} onClick={download}>
        Download CSV
      </div>
    </div>
  );
};

export default forwardRef(DownloadMenu);
