import { useState } from "react";
import styles from "./confirm_add_subject.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import router from "routes";
import { post } from "util/requests";

export default function ConfirmAddSubject(props) {
  const [spinning, setSpinning] = useState();

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Add New Subject</div>
      <div className={styles.para}>
        This will allocate a new subject ID for PGxSAFE.
      </div>

      <div className={styles.buttons}>
        <LoadingButton
          variant="standard"
          loading={false}
          onClick={() => {
            router.navigate(-1);
          }}
        >
          BACK
        </LoadingButton>
        <LoadingButton
          variant="contained"
          loading={spinning}
          onClick={() => {
            setSpinning(true);
            post("/allocate_subject_id").then((ret) => {
              setSpinning(false);
              router.navigate(`/enrollment_complete/${ret.data.subject_id}`);
            });
          }}
        >
          CONTINUE
        </LoadingButton>
      </div>
    </div>
  );
}
