import React, { useRef, useEffect, useState } from "react";
import { get } from "util/requests";
import { DataGrid } from "@mui/x-data-grid";
import styles from "./queries.module.css";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import QueryTable from "./components/query_table";
import TableFileDownload from "ui/table_file_download";

export default function Queries() {
  const getFunction = () =>
    get("/download_queries", {
      params: {
        domain_code: "all",
      },
    });
  return (
    <div className={styles.flex}>
      <div className={styles.header}>
        <div>
          <h3>Queries</h3>
        </div>
        <div>
          <TableFileDownload
            getFunction={getFunction}
            fileName={"ACT Global Query Export"}
          />
        </div>
      </div>
      <QueryTable />
    </div>
  );
}
