import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from './App';
import store from "redux/store";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { TimerProvider } from "./timer.js";
import Routes from "routes";
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById("root"));
document.title = window.location.hostname.includes("actglobaltrial.com")
  ? "ACT Global Trial Platform"
  : "SCTG Trials | Platform";
root.render(
  <React.Fragment>
    <Provider store={store}>
      <TimerProvider>
        <RouterProvider router={Routes} future={{ v7_startTransition: true }} />
      </TimerProvider>
    </Provider>
  </React.Fragment>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
