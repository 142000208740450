import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./invite_user.module.css";

import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { post, get } from "util/requests";
import router from "routes";
import { Dialog } from "@mui/material";
import UserRoleModal from "../user_role_modal";
import DomainSelectModal from "ui/modals/domain_select";
import UserSiteModal from "../user_site_modal";
import UserAccess from "../user_detail/components/user_access";

export default function InviteUser(props) {
  const { cancel, application } = props;
  const study_id = useSelector((state) => state.study.active_study_id);

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    roles: [],
    domains: [],
    study_id,
    sites: [],
    regions: [],
  });
  const [loading, setLoading] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [error, setError] = useState("");
  const [roleSelectorIsOpen, setRoleSelectorIsOpen] = useState(false);
  const [domainModalIsOpenData, setDomainModalIsOpenData] = useState(false);
  const [siteSelectIsOpen, setSiteSelectIsOpen] = useState(false);
  const [domains, setDomains] = useState([]);

  const role_string = userData?.roles?.map((r) => r.name).join(", ") || "";

  useEffect(() => {
    get(`/invite_user_sites_and_roles/${study_id}`).then((ret) => {
      const { data } = ret;
      setSiteList(data.sites);
      setRegionList(data.regions);
      setRoleList(
        data.roles
          // .filter((r) => !r.name.toLowerCase().includes("corelab"))
          .map((r) => ({ name: r.name, id: r.id, role_id: r.id })),
      );
      setDomains(data.domains);
    });
  }, []);

  const handleSendInvite = () => {
    setLoading(true);
    post("/auth/invite_user", { ...userData, application_code: application })
      .then((ret) => {
        setLoading(false);
        router.navigate("/admin/users");
      })
      .catch((err) => {
        console.log(err.response);
        if (
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          setError("User with that email already exists in this study.");
        } else {
          setError("There was an issue, please try again.");
        }
        setLoading(false);
      });
  };

  const setValue = (key, value) => {
    setUserData({ ...userData, [key]: value });
    setError(null);
  };

  const updateUserRoleIds = (role_id_list) => {
    let updated_user = { ...userData };
    updated_user.role = roleList.filter((r) => role_id_list.includes(r.id));
    setUserData(updated_user);
    // setLocalUser(updated_user);
  };

  const updateUserSiteIds = (site_id_list) => {
    let updated_user = { ...userData };
    updated_user.sites = siteList.filter((s) => site_id_list.includes(s.id));
    setUserData(updated_user);
  };

  const updateDomains = (domains) => {
    let _userData = { ...userData };
    _userData.domains = domains;
    console.log(_userData);
    setUserData(_userData);
  };

  const domain_string =
    (userData &&
      userData.domains &&
      domains
        .filter((domain) => userData.domains.includes(domain.code))
        .map((domain) => domain.name)
        .join(", ")) ||
    "";

  const sites_string =
    (userData?.sites &&
      siteList
        .filter((s) => userData.sites.map((s) => s.id).includes(s.id))
        .map((s) => s.name)
        .join(", ")) ||
    "";

  const canSendInvite =
    userData &&
    userData.first_name &&
    userData.last_name &&
    userData.email &&
    (userData.sites?.length || userData.regions?.length) &&
    userData.roles?.length &&
    userData.domains?.length;

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading1}>Invite New User ({application})</div>

      <div className={styles.profile_row}>
        <TextField
          label="First Name"
          placeholder="text"
          value={userData.first_name}
          onChange={(e) => setValue("first_name", e.target.value)}
          autoComplete="off"
          variant="standard"
          sx={{
            "& .MuiInputBase-input": {
              padding: "5px",
              width: "300px",
            },
          }}
        />
      </div>
      <div className={styles.profile_row}>
        <TextField
          label="Last Name"
          placeholder="text"
          value={userData.last_name}
          onChange={(e) => setValue("last_name", e.target.value)}
          autoComplete="off"
          variant="standard"
          sx={{
            "& .MuiInputBase-input": {
              padding: "5px",
              width: "300px",
            },
          }}
        />
      </div>
      <div className={styles.profile_row}>
        <TextField
          label="Email"
          placeholder="text"
          value={userData.email}
          onChange={(e) => setValue("email", e.target.value.trim())}
          autoComplete="off"
          variant="standard"
          sx={{
            "& .MuiInputBase-input": {
              padding: "5px",
              width: "300px",
            },
          }}
        />
      </div>

      <UserAccess
        user={userData}
        setUser={(user) => {
          setUserData(user);
        }}
        siteList={siteList}
        roleList={roleList}
        domainList={domains}
        regionList={regionList}
      />

      <div>
        <LoadingButton
          disabled={!canSendInvite}
          variant="contained"
          loading={loading}
          onClick={handleSendInvite}
        >
          SEND INVITE
        </LoadingButton>
        <LoadingButton
          variant="contained"
          color="inherit"
          sx={{ color: "#555" }}
          style={{ marginLeft: 20 }}
          onClick={cancel}
        >
          CANCEL
        </LoadingButton>
      </div>
      <div style={{ marginTop: 6, color: "red" }}>{error}</div>
    </div>
  );
}
