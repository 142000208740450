import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import styles from "./segment.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import DropMenu from "../../../views/edc/components/drop_menu";
import DomainTag from "views/subjects/components/domain_tag";
import router from "routes";
import { renderFieldComponent } from "./helpers";
import { get, post } from "util/requests";
import HistoryBar from "./components/history_bar";

export default function Segment(props) {
  const {
    title,
    crf_id,
    crf_data,
    subject_id,
    updateCrfStatus,
    updateCrfSignStatus,
    crf_status,
    crf_sign_status,
    showDeleteFormOption,
    deleteForm,
    setQuery,
    updateCrfQueryStatus,
    form_type,
    multi_entry_crf_id,
    updateFieldValue,
    linked_crf_id,
    visit_index,
    is_linked_crf,
    ignore_ref_height,
  } = props;

  const permissions = useSelector((state) => state.user.userData.permissions);
  const user_domains = useSelector((state) => state.user.userData.domains);
  const crfStatus = crf_data?.status || "";
  const [showHistory, setShowHistory] = useState(false);
  const [historyFilters, setHistoryFilters] = useState({});

  const segment_ref = useRef(null);

  useEffect(() => {
    setHistoryFilters({});
  }, [crf_id, subject_id]);

  const toggleHistory = ({ field_id = null }) => {
    if (field_id && field_id !== historyFilters.field_id) {
      let _filters = { ...historyFilters };
      _filters.field_id = field_id;
      setHistoryFilters(_filters);
    } else if (
      field_id &&
      field_id === historyFilters.field_id &&
      showHistory
    ) {
      let _filters = { ...historyFilters };
      delete _filters.field_id;
      setHistoryFilters(_filters);
    }
    // setTimeout(() => setShowHistory(true), 100);
    setShowHistory(true);
  };
  const closeHistory = () => setShowHistory(false);

  return (
    <div className={styles.segment}>
      <div className={styles.top_wrapper}>
        <div className={styles.top_split_left}>
          <strong>{title}</strong>
        </div>

        <div className={styles.top_split_right}>
          <DropMenu
            key={crf_id}
            crf_id={crf_id}
            form_name={crf_data && crf_data.name && crf_data.name.toUpperCase()}
            crf_data={crf_data}
            crf_status={crf_status}
            crf_sign_status={crf_sign_status}
            subject_id={subject_id}
            updateCrfStatus={updateCrfStatus}
            updateCrfSignStatus={updateCrfSignStatus}
            showDeleteFormOption={showDeleteFormOption}
            deleteForm={deleteForm}
            multi_entry_crf_id={multi_entry_crf_id}
            is_linked_crf={is_linked_crf}
          />
        </div>
        {permissions.includes("crf_builder") ? (
          <div className={styles.crf_builder_button}>
            <LoadingButton
              disabled={false}
              variant="contained"
              loading={false}
              onClick={() => {
                router.navigate(
                  `/crf_builder/${crf_id}/${crf_data?.version || "1.0"}`,
                );
              }}
              sx={{
                fontSize: 14,
                padding: "2px",
                paddingLeft: "10px",
                paddingRight: "10px",
                width: "126px",
              }}
            >
              CRF Builder
            </LoadingButton>
          </div>
        ) : null}
      </div>
      <hr />
      <div className={styles.segment_children}>
        <div className={styles.children} ref={segment_ref}>
          {crf_data &&
            crf_data.design &&
            crf_data.design.fields.length &&
            crf_data.design.fields.map((field) =>
              renderFieldComponent(field, crf_data.design.fields, {
                subject_id,
                crf_id,
                setQuery,
                crf_data,
                updateCrfQueryStatus,
                form_type,
                multi_entry_crf_id,
                crfStatus,
                user_domains,
                updateFieldValue,
                linked_crf_id,
                visit_index,
                toggleHistory,
                selected_field_id:
                  (showHistory && historyFilters.field_id) || null,
              }),
            )}
        </div>
        {permissions.includes("crf_change_history") ? (
          <HistoryBar
            subject_id={subject_id}
            crf_id={multi_entry_crf_id ? multi_entry_crf_id : crf_id}
            closeHistory={closeHistory}
            historyFilters={historyFilters}
            setHistoryFilters={setHistoryFilters}
            segment_height={
              ignore_ref_height ? null : segment_ref?.current?.clientHeight
            }
          />
        ) : null}
      </div>
    </div>
  );
}
