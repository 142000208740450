import React, { useState, useRef } from "react";
import styles from "./int.module.css";
import { TextField } from "@mui/material";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import IntUI from "./components/int_ui";
import DataCompleteChangeModal from "ui/crfs/data_complete_change_modal";
import AuditIconUI from "ui/crfs/audit_icon_ui";

export default function Int(props) {
  const {
    field,
    field_id,
    title,
    onUpdate,
    enableQuery,
    length,
    placeHolder,
    read_only,
    crfStatus,
    toggleHistory,
    selected_audit_icon,
  } = props;

  const [showingCompleteChangeModal, setShowingCompleteChangeModal] =
    useState(false);
  const [localVal, setLocalVal] = useState(field.value_obj || {});
  const acceptTempStorage = (reason) => {
    onUpdate({ ...localVal, reason });
    setShowingCompleteChangeModal(false);
  };
  const closeModal = () => {
    setLocalVal({ ...field.value_obj });
    setShowingCompleteChangeModal(false);
  };

  const updateValue = (value_obj) => {
    if (crfStatus === "data_complete") {
      setLocalVal(value_obj);
      setShowingCompleteChangeModal(true);
      return;
    }
    setLocalVal(value_obj);
    onUpdate(value_obj);
  };
  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.input_flex}>
        <div className={styles.left_split}>
          {enableQuery ? (
            <div>
              <NotificationWidget
                {...props}
                currentValueDisplay={localVal.value}
              />
            </div>
          ) : (
            <div>{title}</div>
          )}
        </div>

        <div className={styles.right_split}>
          {read_only || field.read_only ? (
            <React.Fragment>
              <div>{field.value_obj.value}</div>
              <div className={styles.audit_icon}>
                <AuditIconUI
                  onClick={() => toggleHistory({ field_id: field_id })}
                  selected={selected_audit_icon}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <IntUI
                {...props}
                field={{ ...field, value_obj: localVal }}
                onUpdate={updateValue}
                units={(field && field.units) || ""}
              />
              <div className={styles.audit_icon}>
                <AuditIconUI
                  onClick={() => toggleHistory({ field_id: field_id })}
                  selected={selected_audit_icon}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <DividerLine />
      <DataCompleteChangeModal
        isOpen={showingCompleteChangeModal}
        close={closeModal}
        onYes={acceptTempStorage}
      />
    </div>
  );
}
