import React, { useState } from "react";
import styles from "./blood_pressure.module.css";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import BloodPressureUI from "./components/blood_pressure_ui";
import DataCompleteChangeModal from "ui/crfs/data_complete_change_modal";
import AuditIconUI from "ui/crfs/audit_icon_ui";

const systolic_position = "value";
const diastolic_position = "value2";

let tempStorage = null;

export default function BloodPressure(props) {
  const {
    field,
    field_id,
    title,
    onUpdate,
    enableQuery,
    read_only,
    crfStatus,
    toggleHistory,
    selected_audit_icon,
  } = props;

  const [localVal, setLocalVal] = useState((field && field.value_obj) || {});
  const [showingCompleteChangeModal, setShowingCompleteChangeModal] =
    useState(false);

  const commonUpdateValueActions = (value, reason = null) => {
    let obj = { ...localVal };

    obj[systolic_position] = value[systolic_position];
    obj[diastolic_position] = value[diastolic_position];
    obj.value_name = field.value_name;
    obj.value2_name = field.value2_name;
    if (reason) {
      obj.reason = reason;
    }
    setLocalVal(obj);
    onUpdate(obj);
  };
  const updateValue = (value) => {
    if (crfStatus === "data_complete") {
      tempStorage = value;
      setShowingCompleteChangeModal(true);
      return;
    }
    commonUpdateValueActions(value);
  };

  const closeModal = () => {
    setLocalVal({ ...localVal });
    tempStorage = null;
    setShowingCompleteChangeModal(false);
  };
  const acceptTempStorage = (reason) => {
    commonUpdateValueActions(tempStorage, reason);
    setShowingCompleteChangeModal(false);
  };
  const currentValueDisplay =
    `${localVal[systolic_position] || "--"}/${
      localVal[diastolic_position] || "--"
    }` + " mmHg";

  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.input_flex}>
        <div className={styles.left_split}>
          {enableQuery ? (
            <div>
              <NotificationWidget
                {...props}
                currentValueDisplay={currentValueDisplay}
              />
            </div>
          ) : (
            <div>{title}</div>
          )}
        </div>

        <div className={styles["right-split"]}>
          {read_only ? (
            <React.Fragment>
              <div>{currentValueDisplay}</div>
              <div className={styles.audit_icon}>
                <AuditIconUI
                  onClick={() => toggleHistory({ field_id: field_id })}
                  selected={selected_audit_icon}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <BloodPressureUI
                {...props}
                field={{ value_obj: localVal }}
                onUpdate={(value_obj) => {
                  updateValue(value_obj);
                }}
                systolic_position={systolic_position}
                diastolic_position={diastolic_position}
              />
              <div className={styles.audit_icon}>
                <AuditIconUI
                  onClick={() => toggleHistory({ field_id: field_id })}
                  selected={selected_audit_icon}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <DividerLine />
      <DataCompleteChangeModal
        isOpen={showingCompleteChangeModal}
        close={closeModal}
        onYes={acceptTempStorage}
      />
    </div>
  );
}
