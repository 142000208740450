import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { get } from "util/requests";
import moment from "moment";
import router from "routes";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import styles from "./users_table.module.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "first_name", headerName: "First Name", width: 200 },
  { field: "last_name", headerName: "Last Name", width: 150 },
  { field: "email", headerName: "Email", width: 280 },
  { field: "status", headerName: "Status", width: 90 },
  {
    field: "actvated",
    headerName: "Activated",
    width: 90,
    renderCell: ({ row }) =>
      row.activated_on && row.deactivated_on
        ? "no"
        : row.activated_on
          ? "yes"
          : "no",
  },
  { field: "phone", headerName: "Phone", width: 150 },
  {
    field: "last_login",
    headerName: `Last Login (${moment.tz(moment.tz.guess()).zoneAbbr()})`,
    width: 200,
    renderCell: ({ row }) => {
      return (
        (row.last_login &&
          moment(row.last_login).format("yyyy-MMM-DD HH:mm:ss")) ||
        ""
      );
    },
  },
];

export default function UsersTable(props) {
  const { studyConfig } = props;
  const [siteData, setSiteData] = useState([]);
  const [activeApplication, setActiveApplication] = useState(null);
  const [missingPermission, setMissingPermission] = useState(false);
  const [objectData, setObjectData] = useState(null);
  const [filters, setFilters] = useState({});
  const study_id = useSelector((state) => state.study.active_study_id);
  const [search, setSearch] = useState("");

  const search_ref = useRef(null);

  let application_list = [];
  if (studyConfig.etms) {
    application_list.push({ label: "ETMS", value: "etms" });
  }
  if (studyConfig.corelab) {
    application_list.push({ label: "Corelab", value: "corelab" });
  }
  useEffect(() => {
    get(`/invite_user_sites_and_roles/${study_id}`).then((ret) =>
      setObjectData(ret.data),
    );
  }, []);

  const fetch_users = () => {
    get(
      `/users/${study_id}/${activeApplication || application_list[0].value}`,
      { params: filters },
    )
      .then((ret) => setSiteData(ret.data))
      .catch((err) => {
        if (err?.response?.status === 423) {
          setMissingPermission(true);
        }
      });
  };

  useEffect(() => {
    if (!studyConfig) return;
    setMissingPermission(false);
    fetch_users();
  }, [activeApplication, studyConfig, filters]);

  const onKeyDown = (key) => {
    if (key.keyCode === 13) {
      search_ref.current.blur();
    }
  };

  const searchFieldBlur = () => {
    if (search) {
      setFilters({ ...filters, search });
    } else {
      let _filters = { ...filters };
      delete _filters.search;
      setFilters(_filters);
    }
    fetch_users();
  };

  const render_site_option = (site) => {
    if ([null, undefined].includes(site)) {
      return "";
    } else if (site.constructor === Object) {
      return `${site.code} - ${site.name}`;
    } else if (site.constructor === Number) {
      return render_site_option(
        objectData?.sites.find((s) => s.id === parseInt(site)),
      );
    } else return "";
  };
  const render_role_option = (role) => {
    if ([null, undefined].includes(role)) {
      return "";
    } else if (role.constructor === Object) {
      return `${role.name}`;
    } else if (role.constructor === Number) {
      return render_role_option(
        objectData?.roles.find((r) => r.id === parseInt(role)),
      );
    } else return "";
  };
  console.log("filters", filters);
  return (
    <div>
      <div className={styles.table_header}>
        <div className={styles.table_header_left}>
          <div className={styles.application_dropdown}>
            <Select
              value={activeApplication || application_list[0].value}
              sx={{
                fontSize: 14,
                minWidth: 120,
                height: 33,
                padding: 0,
                alignSelf: "center",
              }}
              onChange={(e) => {
                setActiveApplication(e.target.value);
              }}
            >
              {application_list.map((o) => (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={styles.filter_item}>
            <TextField
              inputRef={search_ref}
              value={search}
              type="search"
              label="Search"
              size="small"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onBlur={searchFieldBlur}
              onKeyDown={onKeyDown}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  paddingLeft: "10px",
                  textAlign: "left",
                },
                "& .MuiFormLabel-root": {
                  transform: "translate(14px, 6px) scale(1)",
                },
                "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-filled": {
                  transform: "translate(14px, -9px) scale(0.8)",
                },
              }}
            />
          </div>{" "}
          <div className={styles.filter_item}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={objectData?.sites.map(render_site_option) || []}
              value={render_site_option(filters?.site_id)}
              size="small"
              sx={{
                "& .MuiInputBase-root": {
                  fontSize: "12px",
                  margin: 0,
                  padding: 0,
                },
                "& .MuiAutocomplete-root": {
                  margin: 0,
                  padding: 0,
                },
                "& .MuiFormLabel-root": {
                  fontSize: "1rem",
                  lineHeight: "1.2rem",
                },
                width: 280,
              }}
              renderInput={(params) => <TextField {...params} label="Site" />}
              onChange={(e, value) => {
                let site_id = objectData?.sites.find(
                  (s) => s.code === value?.split(" ")[0],
                )?.id;

                let _filters = { ...filters };
                if (site_id) {
                  setFilters({ ..._filters, site_id });
                } else {
                  delete _filters.site_id;
                  setFilters(_filters);
                }
              }}
            />
          </div>
          <div className={styles.filter_item}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={objectData?.roles.map(render_role_option) || []}
              value={render_role_option(filters?.role_id)}
              size="small"
              sx={{
                "& .MuiInputBase-root": {
                  fontSize: "12px",
                  margin: 0,
                  padding: 0,
                },
                "& .MuiAutocomplete-root": {
                  margin: 0,
                  padding: 0,
                },
                "& .MuiFormLabel-root": {
                  fontSize: "1rem",
                  lineHeight: "1.2rem",
                },
                width: 240,
              }}
              renderInput={(params) => <TextField {...params} label="Role" />}
              onChange={(e, value) => {
                let role_id = objectData?.roles.find(
                  (r) => r.name === value,
                )?.id;
                let _filters = { ...filters };
                if (role_id) {
                  setFilters({ ..._filters, role_id });
                } else {
                  delete _filters.role_id;
                  setFilters(_filters);
                }
              }}
            />
          </div>
        </div>
        {!missingPermission ? (
          <LoadingButton
            variant="contained"
            loading={false}
            style={{ marginBottom: 6 }}
            onClick={() =>
              router.navigate(
                `/admin/user/invite/${activeApplication || application_list[0].value}`,
              )
            }
          >
            <strong>INVITE USER</strong>
          </LoadingButton>
        ) : null}
      </div>
      {missingPermission ? (
        <div style={{ marginTop: 30 }}>
          You do not have the appropriate permissions to manage this.
        </div>
      ) : null}
      {!missingPermission ? (
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={siteData}
            columns={columns}
            disableColumnSelector
            disableRowSelectionOnClick
            disableColumnMenu
            pageSize={5}
            rowsPerPageOptions={[5]}
            hideFooterSelectedRowCount
            pageSizeOptions={[]}
            columnHeaderHeight={34}
            rowHeight={34}
            sx={{
              boxShadow: 0,
              border: 1,
              borderColor: "#f0f0f0",
              "& .MuiDataGrid-cell:hover": {
                color: "green",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#e9f1fd",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
                color: "#333",
              },
            }}
            onRowClick={(row) => {
              router.navigate(`/admin/users/${row.id}`);
            }}
          />
        </div>
      ) : null}
    </div>
  );
}
