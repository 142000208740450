import React from "react";
import UserEntryPoint from "views/user_entry_point";
import { createBrowserRouter, useRouteError } from "react-router-dom";
import ElectronicConsentForm from "views/electronic_consent_form";
import PasswordResetInitiated from "views/password_reset";
import PasswordResetForm from "views/password_reset_form";
import InviteCompletion from "views/invite_completion";
import ErrorBoundary from "views/error_boundary";

const router = createBrowserRouter(
  [
    { path: "consent/electronic_link/:id", element: <ElectronicConsentForm /> },
    { path: "password_reset_sent", element: <PasswordResetInitiated /> },
    {
      path: "password_reset_form/:reset_token",
      element: <PasswordResetForm />,
    },
    {
      path: "user_invite_completion/:invite_token",
      element: <InviteCompletion />,
    },
    {
      path: "/*",
      element: <UserEntryPoint />,
      errorElement: <ErrorBoundary />,
    },
    // { path: "/0/*", element: <BaseRoute /> },
  ],
  {
    future: {
      v7_relativeSplatPath: true, // Enables relative paths in nested routes
      v7_fetcherPersist: true, // Retains fetcher state during navigation
      v7_normalizeFormMethod: true, // Normalizes form methods (e.g., POST or GET)
      v7_partialHydration: true, // Supports partial hydration for server-side rendering
      v7_skipActionErrorRevalidation: true, // Prevents revalidation when action errors occur
    },
  },
);

export default router;
