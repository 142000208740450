import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./site_detail.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import moment from "moment-timezone";
import Autocomplete from "@mui/material/Autocomplete";
import { put, get, post } from "util/requests";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Checkbox } from "@mui/material";
import { useLocation } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import router from "routes";

const Form = (props) => {
  const { setEdit, site, regionList, studyConfig } = props;
  return (
    <div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Name:</div>
        <div>{site.name}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Code:</div>
        <div>{site.code}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Location:</div>
        <div>{site.location}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Time Zone:</div>
        <div>{site.time_zone}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Region:</div>
        <div>{regionList.find((r) => r.id === site.region_id)?.name || ""}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>CRF Version:</div>
        <div>{site.crf_version}</div>
      </div>
      {/*}
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Type:</div>
        <div>{site.type}</div>
      </div>*/}
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Enable Site:</div>
        <div>
          {site.is_enabled ? (
            <CheckIcon sx={{ color: "green", fontSize: 20 }} />
          ) : (
            <CloseIcon sx={{ color: "red", fontSize: 20 }} />
          )}
        </div>
      </div>
      {/*
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Enable e-Consent:</div>
        <div>
          {site.is_econsent_enabled ? (
            <CheckIcon sx={{ color: "green", fontSize: 20 }} />
          ) : (
            <CloseIcon sx={{ color: "red", fontSize: 20 }} />
          )}
        </div>
      </div>*/}
      {studyConfig.etms ? (
        <div className={styles.profile_row}>
          <div className={styles.profile_row_title}>Enable Randomization:</div>
          <div>
            {site.is_randomization_enabled ? (
              <CheckIcon sx={{ color: "green", fontSize: 20 }} />
            ) : (
              <CloseIcon sx={{ color: "red", fontSize: 20 }} />
            )}
          </div>
        </div>
      ) : null}
      {studyConfig.corelab ? (
        <div className={styles.profile_row}>
          <div className={styles.profile_row_title}>Enable Corelab:</div>
          <div>
            {site.corelab ? (
              <CheckIcon sx={{ color: "green", fontSize: 20 }} />
            ) : (
              <CloseIcon sx={{ color: "red", fontSize: 20 }} />
            )}
          </div>
        </div>
      ) : null}
      <LoadingButton
        variant="contained"
        loading={false}
        onClick={() => {
          setEdit(true);
        }}
      >
        EDIT
      </LoadingButton>
    </div>
  );
};

const EditForm = (props) => {
  const { user, setEdit, setSite, regionList, newSite, study_id, studyConfig } =
    props;
  const [error, setError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [localUser, setLocalUser] = useState({
    name: "",
    code: "",
    type: "",
    time_zone: null,
    phone: "",
    email: "",
    site: "",
    region_id: 0,
    location: "",
    is_enabled: "",
    is_able_to_enroll: "",
    is_econsent_enabled: "",
    is_randomization_enabled: "",
    study_id,
    crf_version: "",
  });
  const [crfVersions, setCrfVersions] = useState([]);
  useEffect(() => {
    // if (newSite) {
    get(`/study_crf_versions/${study_id}`).then((ret) =>
      setCrfVersions(ret.data),
    );
    // }
    setLocalUser({ ...user });
  }, []);

  const setValue = (key, value) => {
    setLocalUser({ ...localUser, [key]: value });
  };

  const canSave = !(
    localUser.name &&
    localUser.code &&
    localUser.location &&
    localUser.region_id &&
    localUser.time_zone
  );

  // console.log(regionList, localUser);

  return (
    <div>
      <div className={styles.profile_row}>
        <div>
          <div className={styles.section_title}>Name</div>
          <TextField
            label=""
            placeholder=""
            value={localUser.name}
            onChange={(e) => {
              setValue("name", e.target.value);
              setError("");
            }}
            autoComplete="off"
            sx={{
              "& .MuiInputBase-input": {
                padding: "6px",
                width: "300px",
              },
            }}
          />
        </div>
      </div>
      <div className={styles.profile_row}>
        <div>
          <div className={styles.section_title}>Code</div>
          <TextField
            label=""
            placeholder=""
            value={localUser.code}
            onChange={(e) => {
              setValue("code", e.target.value);
              setError("");
            }}
            autoComplete="off"
            sx={{
              "& .MuiInputBase-input": {
                padding: "6px",
                width: "300px",
              },
            }}
          />
        </div>
      </div>
      <div className={styles.profile_row}>
        <div>
          <div className={styles.section_title}>Location</div>
          <TextField
            label=""
            placeholder="Hospital or clinic name, state/province"
            value={localUser.location}
            onChange={(e) => setValue("location", e.target.value)}
            autoComplete="off"
            sx={{
              "& .MuiInputBase-input": {
                padding: "6px",
                width: "300px",
              },
            }}
          />
        </div>
      </div>

      <div className={styles.profile_column}>
        <div className={styles.section_title}>Time Zone</div>
        <Autocomplete
          value={localUser.time_zone}
          options={moment.tz.names()}
          size="small"
          sx={{
            "& .MuiAutocomplete-root": { fontSize: "10px" },
            fontSize: 12,
            height: 30,
            padding: 0,
            alignSelf: "left",
            width: 312,
          }}
          renderInput={(params) => <TextField {...params} label="" />}
          onChange={(e, value) => {
            setValue("time_zone", value);
          }}
        />
      </div>
      <div className={styles.profile_column}>
        <div className={styles.section_title}>Region</div>
        <Autocomplete
          value={
            regionList.find((r) => r.id === localUser.region_id)?.name || ""
          }
          options={regionList.map((r) => ({ key: r.id, label: r.name }))}
          size="small"
          sx={{
            "& .MuiAutocomplete-root": { fontSize: "10px" },
            fontSize: 12,
            height: 30,
            padding: 0,
            alignSelf: "left",
            width: 312,
          }}
          renderInput={(params) => <TextField {...params} label="" />}
          onChange={(e, value) => {
            setValue("region_id", value.key);
          }}
        />
      </div>
      <div className={styles.profile_column}>
        <div className={styles.section_title}>CRF Version</div>
        <Autocomplete
          value={localUser.crf_version}
          options={crfVersions}
          size="small"
          sx={{
            "& .MuiAutocomplete-root": { fontSize: "10px" },
            fontSize: 12,
            height: 30,
            padding: 0,
            alignSelf: "left",
            width: 312,
          }}
          renderInput={(params) => <TextField {...params} label="" />}
          onChange={(e, value) => {
            setValue("crf_version", value);
          }}
        />
      </div>
      {/*}
      <div className={styles.profile_column}>
        <div className={styles.section_title}>Type</div>
        <Autocomplete
          value={localUser.type}
          options={["central", "randomization"]}
          size="small"
          sx={{
            "& .MuiAutocomplete-root": { fontSize: "10px" },
            fontSize: 12,
            height: 30,
            padding: 0,
            alignSelf: "center",
            width: 312,
          }}
          renderInput={(params) => <TextField {...params} label="" />}
          onChange={(e, value) => {
            setValue("type", value);
          }}
        />
      </div>*/}
      <div
        className={styles.checkbox_row}
        onClick={() => setValue("is_enabled", !localUser.is_enabled)}
      >
        <Checkbox checked={localUser.is_enabled} />
        <div>Enable Site</div>
      </div>
      {/*
      <div className={styles.checkbox_row}>
        <Checkbox
          checked={localUser.is_econsent_enabled}
          className={styles.checkbox}
          onChange={(e) => setValue("is_econsent_enabled", e.target.checked)}
        />
        <div>Enable e-Consent</div>
      </div>*/}
      {studyConfig.etms ? (
        <div
          className={styles.checkbox_row}
          onClick={() =>
            setValue(
              "is_randomization_enabled",
              !localUser.is_randomization_enabled,
            )
          }
        >
          <Checkbox
            checked={localUser.is_randomization_enabled}
            className={styles.checkbox}
          />
          <div>Enable Randomization</div>
        </div>
      ) : null}
      {studyConfig.corelab ? (
        <div className={styles.checkbox_row}>
          <Checkbox
            checked={localUser.corelab}
            className={styles.checkbox}
            onChange={(e) => setValue("corelab", e.target.checked)}
          />
          <div>Enable Corelab</div>
        </div>
      ) : null}

      <LoadingButton
        variant="contained"
        loading={isSaving}
        onClick={() => {
          setIsSaving(true);
          if (!newSite) {
            put(`/site_details/${localUser.id}`, { ...localUser, study_id })
              .then(() => {
                setSite(localUser);
                setIsSaving(false);
                setEdit(false);
              })
              .catch((err) => {
                setError(
                  "Cannot re-use site name or code that already exists.",
                );
                setIsSaving(false);
              });
          } else {
            post("/site", { ...localUser, study_id })
              .then(() => {
                router.navigate("/admin/sites");
              })
              .catch((err) => {
                setError(
                  "Cannot re-use site name or code that already exists.",
                );
                setIsSaving(false);
              });
          }
        }}
        disabled={canSave}
      >
        SAVE
      </LoadingButton>
      <LoadingButton
        variant="contained"
        color="inherit"
        sx={{ color: "#555" }}
        loading={false}
        onClick={() => {
          if (!newSite) {
            setEdit(false);
          } else {
            router.navigate("/admin/sites");
          }
        }}
        style={{ marginLeft: 20 }}
      >
        CANCEL
      </LoadingButton>
      <div className={styles.error}> {error}</div>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      className={styles.tab_panel}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default function SiteDetails(props) {
  const { add, studyConfig } = props;
  const study_id = useSelector((state) => state.study.active_study_id);
  const [isEdit, setEdit] = useState(add || false);
  const [regionList, setRegionList] = useState([]);
  const location = useLocation();
  const [site, setSite] = useState({
    email: "",
    first_name: "",
    last_name: "",
    time_zone: "",
    phone: "",
  });

  useEffect(() => {
    get("/regions").then((ret) => {
      const { data } = ret;
      setRegionList(data);
    });
  }, []);

  useEffect(() => {
    const id =
      location && location.pathname && location.pathname.split("/").slice(-1);
    //the above has some funkiness with the url routing, so check if it's an int before requesting the following
    if (id && parseInt(id)) {
      get(`/site/${id}/${study_id}`).then((ret) => setSite(ret.data));
    }
  }, [location]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading1}>
        {add ? "Add Site" : `Site: ${site.name}`}
      </div>
      {isEdit ? (
        <EditForm
          user={site}
          setEdit={setEdit}
          setSite={setSite}
          regionList={regionList}
          newSite={add}
          study_id={study_id}
          studyConfig={studyConfig}
        />
      ) : (
        <Form
          site={site}
          setEdit={setEdit}
          regionList={regionList}
          study_id={study_id}
          studyConfig={studyConfig}
        />
      )}
    </div>
  );
}
