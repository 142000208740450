import React, { useState, useEffect } from "react";
import styles from "./region_select.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { Dialog, Checkbox } from "@mui/material";

export default function RegionSelectModal(props) {
  const { activeRegions, regionList, is_open, close, updateParent, title } =
    props;
  const [domains, setDomains] = useState(activeRegions || []);

  useEffect(() => {
    setDomains(activeRegions || []);
  }, [activeRegions]);

  const updateDomains = (domain_code, checked) => {
    let updated_domains = [...domains];
    if (checked) {
      updated_domains.push(domain_code);
    } else {
      updated_domains = updated_domains.filter((code) => code !== domain_code);
    }
    setDomains(updated_domains);
  };

  return (
    <Dialog open={is_open} onClose={close}>
      <div className={styles.dialog_wrapper}>
        <div className={styles.roles_dialog_title}>
          <strong>{title}</strong>
        </div>
        <div className={styles.role_wrapper}>
          {regionList.map((domain) => (
            <div className={styles.role_row}>
              <Checkbox
                key={domain.code}
                checked={domains.includes(domain.id)}
                className={styles.checkbox}
                onChange={(e) => {
                  updateDomains(domain.id, e.target.checked);
                }}
              />
              <div>{domain.name}</div>
            </div>
          ))}
        </div>
        <div className={styles.dialog_button_wrapper}>
          <LoadingButton
            variant="contained"
            loading={false}
            onClick={() => {
              updateParent(domains);
              close();
            }}
          >
            UPDATE
          </LoadingButton>
          <LoadingButton variant="standard" loading={false} onClick={close}>
            CANCEL
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );
}
