import React, { forwardRef } from "react";
import styles from "../subjects.module.css";
import { useSelector } from "react-redux";

const AddSubjectMenu = (props, ref) => {
  const { router } = props;
  const user_data = useSelector((state) => state.user.userData);
  console.log("user_data", user_data);

  return (
    <div className={styles.hover_menu} ref={ref}>
      <div
        className={styles.menu_button}
        onClick={() => {
          if (user_data.active_study_id === 5) {
            router.navigate("/confirm_add_subject");
          } else {
            router.navigate("/select_domain");
          }
        }}
      >
        Add Subject to Trial
      </div>
    </div>
  );
};

export default forwardRef(AddSubjectMenu);
