import { useState, useRef, useEffect } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DownloadMenu from "./components/download_menu";
import styles from "./download_menu.module.css";

export default function TableFileDownload(props) {
  const { getFunction, fileName } = props;

  const [isDownloadMenuOpen, setDownloadMenuOpen] = useState(false);
  const downloadMenuRef = useRef();

  const handleDocumentClick = (event) => {
    if (
      event.target &&
      downloadMenuRef &&
      downloadMenuRef.current &&
      !downloadMenuRef.current.contains(event.target)
    ) {
      setDownloadMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  return (
    <div>
      <FileDownloadIcon
        className={styles.table_action_icon}
        onClick={() => setDownloadMenuOpen(true)}
      />
      {isDownloadMenuOpen ? (
        <DownloadMenu
          ref={downloadMenuRef}
          getFunction={getFunction}
          fileName={fileName}
        />
      ) : null}
    </div>
  );
}
